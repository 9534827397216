import React from 'react';
import styled from '@emotion/styled';
import {
	ArrowLink,
	Flex,
	H3,
	H4,
	ITheme,
	Shimmer,
	ShimmerPlaceholderText,
	StyledTextFieldErrorMessage,
} from '../../../../../ui';
import { getGradientBackground } from '../../../../shared/styles';
import { IOffer } from '../../../domain/models/IOffer';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { BadgeTag, BadgeTagVariant } from '../../shared/BadgeTag';
import { SolidContentWrap } from '../../../../shared/components';
import { Spinner } from '../../../../shared/components/spinner/Spinner';
import RedeemedIcon from '../../../../../../assets/redeemed.svg?reference';
import { useFormatNumberWithSeparator } from '../../../../shared/utils/useFormatNumberWithSeparator';
import themed from '../../../../../ui/themer/themed';
import Responsive from '../../../../../ui/utils/Responsive';
import { Color } from '../../../../shared/styles/Color';

interface IOfferProps {
	offer: IOffer;
	error: boolean;
	selected: boolean;
	loading: boolean;
	onClick: () => void;
}
export const OfferCard = ({
	offer,
	selected,
	error,
	loading,
	onClick,
}: IOfferProps) => {
	const { t } = useTranslation();
	const { formatWithSeparator } = useFormatNumberWithSeparator();

	return (
		<StyledOfferCard onClick={onClick}>
			<StyledBrandSolidContentWrap selected={selected}>
				<StyledOfferTitle>{offer.title}</StyledOfferTitle>
				<Flex
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					{!!offer.points && (
						<BadgeTag
							variant={
								selected ? BadgeTagVariant.Inverted : BadgeTagVariant.Default
							}
							badge={selected ? RedeemedIcon : undefined}
							text={
								selected
									? t('loyalty_redeemed_offer_label')
									: t('loyalty_offer_points_template', {
											points: formatWithSeparator(offer.points),
									  })
							}
						/>
					)}
					<Flex
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						{loading ? (
							<Spinner />
						) : (
							<StyledArrowLink selected={selected}>
								{t('loyalty_offers_redeem_label')}
							</StyledArrowLink>
						)}
					</Flex>
				</Flex>
			</StyledBrandSolidContentWrap>
			{error && (
				<StyledTextFieldErrorMessage role="alert">
					{t('loyalty_redeemed_offer_error_message')}
				</StyledTextFieldErrorMessage>
			)}
		</StyledOfferCard>
	);
};

export const ShimmerOfferCard = () => (
	<StyledSolidContentWrap>
		<Shimmer>
			<StyledVertical>
				<ShimmerPlaceholderText width="100%" />
				<hr />
				<hr />
				<Flex direction="row" justifyContent="space-between">
					<p>
						<ShimmerPlaceholderText width="50px" />
					</p>
					<p>
						<ShimmerPlaceholderText width="100px" />
					</p>
				</Flex>
			</StyledVertical>
		</Shimmer>
	</StyledSolidContentWrap>
);

const StyledArrowLink = styled(ArrowLink)<{ selected?: boolean }>`
	disable: ${({ selected }) => selected};
	${({ selected }) => !selected && 'color: #fff;'};
	&:hover {
		${({ selected }) => !selected && 'color: #fff;'};
		&:after {
			width: 100%;
			background: ${({ selected }) => (selected ? 'transparent' : '#fff')};
			transition: 0.2s width 0.05s, 0.2s background 0.05s;
		}
		g {
			stroke: currentColor;
		}
	}
	g {
		${({ selected }) => !selected && 'stroke: #fff;'};
	}
`;

const StyledOfferTitle = styled(H4.withComponent(H3))`
	font-size: 21px;
	color: ${Color.TextWhite};
`;

const StyledOfferCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	width: 384px;

	div[role='alert'] {
		padding-left: 0;
	}

	${StyledTextFieldErrorMessage} {
		font-weight: 700;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 100%;
	}
`;
const StyledBrandSolidContentWrap = styled.div<{ selected?: boolean }, ITheme>`
	width: 384px;
	height: 120px;

	display: flex;
	justify-content: space-between;
	flex-direction: column;

	border-radius: 12px;
	padding: 16px;

	&:hover {
		box-shadow: ${themed(({ card }) => card.fullyClickable.hover.boxShadow)};
		cursor: pointer;
	}

	h4 {
		font-size: 21px;
		line-height: 25px;
		color: ${({ selected }) => (selected ? '#000' : '#fff')};
		margin: 0;
	}

	${({ selected }) =>
		selected
			? `background: rgba(247, 247, 247, 1);`
			: getGradientBackground('purple-blue')};

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 100%;
		height: 106px;
		padding: 16px;
		flex: none;
		h4 {
			font-size: 16px;
		}
	}
`;
const StyledSolidContentWrap = styled(
	Shimmer.withComponent(SolidContentWrap).withComponent(
		StyledBrandSolidContentWrap,
	),
)`
	max-width: 380px;
	margin: 0;
	padding: 16px;
`;

const StyledVertical = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;
