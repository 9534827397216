import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import { ArrowLink, Flex, ShimmerPlaceholderText } from '../../../../../ui';
import { useAuthStore } from '../../../../auth/shared/store/useAuthStore';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { OfferCard, ShimmerOfferCard } from './OfferCard';
import { useLoyaltyAccountStore } from '../../../domain/stores/useLoyaltyAccountStore';
import { Section } from '../../../../content-sections/ui/components/Section';
import { IOffer } from '../../../domain/models/IOffer';
import { AMOUNT_OF_CARDS_TO_SHOW } from '../../../domain/constants';
import { useUrlForPageId } from '../../../../app/pages/ui/utils/useUrlForPageId';
import { PassengersPageId } from '../../../../app/pages/domain/constants/PageId';
import { useNewBookingStore } from '../../../../parking/domain/booking/stores/useNewBookingsStore';
import { useLocation } from '../../../../shared/router/useLocation';
import { useNavigate } from '../../../../shared/router/useNavigate';
import { useUpdateOffers } from '../hooks/useUpdateOffers';
import { updatePromoCodeQuery } from '../../../../shared/router/updatePromoCodeQuery';
import { removePromoCodeQuery } from '../../../../shared/router/removePromoCodeQuery';
import { useRemoveOfferPromoCode } from '../../../../shared/account/domain/hooks/useRemoveOfferPromoCode';
import useGeneratePromoCode from '../../../../parking/ui/search/helpers/generatePromoCode';
import useFindOfferFromQuery from '../hooks/useFindOfferFromQuery';
import LockIconOutlined from '../../../../../../assets/lock-outlined.svg';
import { Color } from '../../../../shared/styles/Color';
import Responsive from '../../../../../ui/utils/Responsive';

interface IRewardOffersProps {
	hasHeader?: boolean;
	showNoRewardsAvailableContainer?: boolean;
}

export const RewardOffers = observer(
	({ hasHeader, showNoRewardsAvailableContainer }: IRewardOffersProps) => {
		const router = useNavigate();
		const { t } = useTranslation();
		const { pathname } = useLocation();
		const parkingRedirectUrl = useUrlForPageId(PassengersPageId.Parking);

		const { isAuthenticated } = useAuthStore();
		const newBookingStore = useNewBookingStore();
		const loyaltyStore = useLoyaltyAccountStore();
		const {
			loyaltyDetails,
			selectedOffer,
			isLoading,
			isPromoCodeLoading,
			promoCode: loyaltyPromoCode,
		} = loyaltyStore;

		const { clearPromocode } = useRemoveOfferPromoCode();
		const { generatePromoCode, hasPromoCodeError, setHasPromoCodeError } =
			useGeneratePromoCode(loyaltyStore);

		const offers = useUpdateOffers(loyaltyDetails, selectedOffer, hasHeader);
		const newSelectedOffer = useFindOfferFromQuery(loyaltyDetails?.offers);

		useEffect(() => {
			if (newSelectedOffer) {
				handleOfferCardClick(newSelectedOffer);
			}
		}, [newSelectedOffer]);

		if (!isAuthenticated) {
			return null;
		}

		const cleanPreviousPromoCodeState = () => {
			setHasPromoCodeError(false);
			clearPromocode();
			removePromoCodeQuery(parkingRedirectUrl);
		};

		const handleOfferCardClick = async (offer: IOffer | undefined) => {
			cleanPreviousPromoCodeState();
			if (!offer) {
				return;
			}

			const promoCode = await generatePromoCode(offer);
			if (!promoCode || !offer.title) {
				return;
			}

			// add generated promocode to the booking store
			newBookingStore.applyOfferPromoCode(promoCode, offer.title || '');

			const updatedUrl = `${parkingRedirectUrl}/?offerid=${offer.id}&promocode=${promoCode}`;
			// navigate to Parking page with promocode if user is not there
			if (!pathname.includes('parking')) {
				router(updatedUrl);
			} else {
				// live update promo code in the query
				updatePromoCodeQuery(updatedUrl);
			}
		};

		if (isLoading) {
			return (
				<Section>
					<StyledMarginFlex
						direction="row"
						justifyContent="space-between"
						alignItems="center"
					>
						<ShimmerPlaceholderText width="250px" />
					</StyledMarginFlex>
					<StyledFlexContainer
						wrap="wrap"
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
					>
						<ShimmerOfferCard />
					</StyledFlexContainer>
				</Section>
			);
		}

		return (
			<>
				{!!offers.length && !isLoading ? (
					<Section>
						{hasHeader && (
							<StyledMarginFlex
								direction="row"
								justifyContent="space-between"
								alignItems="center"
							>
								<h2>{t('loyalty_reward_offers_label')}</h2>
								{!isLoading &&
									loyaltyDetails?.offers &&
									loyaltyDetails?.offers?.length > AMOUNT_OF_CARDS_TO_SHOW && (
										<ArrowLink href="/account/offers">
											{t('web_view_all_label')}
										</ArrowLink>
									)}
							</StyledMarginFlex>
						)}
						<StyledFlexContainer
							wrap="wrap"
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
						>
							{offers.map((offer) => (
								<OfferCard
									key={offer.id}
									offer={offer}
									error={!!hasPromoCodeError && selectedOffer?.id === offer.id}
									selected={
										selectedOffer?.id === offer.id && !!loyaltyPromoCode
									}
									loading={isPromoCodeLoading && selectedOffer?.id === offer.id}
									onClick={() => handleOfferCardClick(offer)}
								/>
							))}
						</StyledFlexContainer>
					</Section>
				) : showNoRewardsAvailableContainer ? (
					<Section>
						<NoRewardsBlock>
							<NoRewardsTitleContainer>
								<NoRewardsTitle>{t('loyalty_no_rewards_title')}</NoRewardsTitle>
								<LockIconOutlined width={28} height={28} />
							</NoRewardsTitleContainer>
							<NoRewardsDescription>
								{t('loyalty_no_rewards_description')}
							</NoRewardsDescription>
						</NoRewardsBlock>
					</Section>
				) : null}
			</>
		);
	},
);

const StyledMarginFlex = styled(Flex)`
	margin-bottom: 12px;
`;
const StyledFlexContainer = styled(Flex)`
	gap: 24px;
	align-items: flex-start;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		gap: 16px;
		flex-direction: column;
	}
`;

const NoRewardsBlock = styled.div`
	background-color: #f7f7f7;
	display: inline-block;
	flex-direction: column;
	padding: 12px 12px 16px 12px;
	border-radius: 16px;
`;

const NoRewardsTitle = styled.p`
	flex: 1;
	font-size: 18px;
	color: ${Color.Background1};
	font-weight: 700;
	margin: 0;
`;

const NoRewardsTitleContainer = styled.div`
	display: flex;

	svg {
		border: 5px solid rgba(0, 0, 0, 0.2);
		border-radius: 50%;
	}
`;

const NoRewardsDescription = styled.p`
	font-size: 16px;
	margin: 0;
`;
