export const useFormatNumberWithSeparator = () => {
	const formatWithSeparator = (number?: number) => {
		if (typeof number === 'undefined') return '';
		const { locale } = Intl.NumberFormat().resolvedOptions();

		const formatter = new Intl.NumberFormat(locale);

		return formatter.format(number);
	};

	return { formatWithSeparator };
};
