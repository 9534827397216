import { useState } from 'react';
import { IOffer } from '../../../../account/domain/models/IOffer';
import { LoyaltyAccountStore } from '../../../../account/domain/stores/LoyaltyAccountStore';

const useGeneratePromoCode = (loyaltyStore: LoyaltyAccountStore) => {
	const [hasPromoCodeError, setHasPromoCodeError] = useState<boolean>();

	const generatePromoCode = async (
		offer: IOffer,
	): Promise<string | undefined> => {
		const { promoCode, error } = await loyaltyStore.loadCustomerPromoCode(
			offer,
		);
		if (error || !promoCode) {
			setHasPromoCodeError(!!error);
			return undefined;
		}
		return promoCode;
	};
	return { generatePromoCode, hasPromoCodeError, setHasPromoCodeError };
};

export default useGeneratePromoCode;
