import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import SpinnerImage from '../../../../../assets/spinner.svg?reference';

export const Spinner = () => (
	<StyledSpinner className="spinner">
		<img src={SpinnerImage} alt="loading" />
	</StyledSpinner>
);

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
  0% {
    transform: rotate(0deg);
  }
`;

const StyledSpinner = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	animation: ${rotate} 1.2s linear infinite;
`;
