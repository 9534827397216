import { useEffect, useState } from 'react';
import { usePromoCodeFromQuery } from '../../../../parking/ui/search/helpers/usePromoCodeFromQuery';
import { IOffer } from '../../../domain/models/IOffer';
import { useLoyaltyAccountStore } from '../../../domain/stores/useLoyaltyAccountStore';

const useFindOfferFromQuery = (offers: IOffer[] | undefined) => {
	const { queryOfferId } = usePromoCodeFromQuery();
	const { selectedOffer } = useLoyaltyAccountStore();
	const [newSelectedOffer, setNewSelectedOffer] = useState<
		IOffer | undefined
	>();
	useEffect(() => {
		if (queryOfferId) {
			const offer = offers?.find((item) => item.id === queryOfferId);
			if (offer && !selectedOffer) {
				setNewSelectedOffer(offer);
			}
		}
	}, [queryOfferId, offers, selectedOffer]);

	return newSelectedOffer;
};

export default useFindOfferFromQuery;
