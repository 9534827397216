import { useMemo } from 'react';
import { ILoyaltyDetails } from '../../../domain/models/ILoyaltyDetails';
import { IOffer } from '../../../domain/models/IOffer';
import { AMOUNT_OF_CARDS_TO_SHOW } from '../../../domain/constants';

export const useUpdateOffers = (
	loyaltyDetails?: ILoyaltyDetails,
	selectedOffer?: IOffer,
	hasHeader?: boolean,
) =>
	useMemo(() => {
		const allOffers = (loyaltyDetails?.offers ?? []).slice();

		if (!hasHeader) {
			// If there is no header, we show all offers.
			return allOffers;
		}

		const firstFewOffers = allOffers.slice(0, AMOUNT_OF_CARDS_TO_SHOW);
		const isSelectedOfferInFirstFewOffers = firstFewOffers.some(
			(offer) => offer.id === selectedOffer?.id,
		);

		if (selectedOffer) {
			if (!isSelectedOfferInFirstFewOffers) {
				// If selected offer is not in the first few offers,
				// we move it to the front of the array.
				firstFewOffers.unshift(selectedOffer);
				firstFewOffers.pop();
			}
		}
		return firstFewOffers;
		// The memo will rerun when loyaltyDetails changes, just like in the original code.
	}, [loyaltyDetails]);
